.navbar {
  border-bottom: 5px solid #dbdbdb;
  background: white;
}
.navbar-brand {
  margin: 0px 10px;
}
.navbar.dash_layout {
  position: relative !important;
}

nav .nav-link {
  color: #17a2b8;
  font-weight: bold;
}

.drawer_btn {
  color: #0062cc;
  cursor: pointer;
  margin-right: 10px;
}

.app_container {
  margin-top: 80px;
}
.root_loader {
  display: flex;
  /* height: 100%; */
  width: 100%;
  text-align: center;
  margin-top: 350px;
  position: absolute;
  /* align-items: center; */
  justify-content: center;
  /* background-color: #0062cc; */
}
/* .ag-root ::-webkit-scrollbar {
  width: 2em;
  height: 2em
}
.ag-root ::-webkit-scrollbar-button {
  background: #ccc
}
.ag-root ::-webkit-scrollbar-track-piece {
  background: #888
}
.ag-root ::-webkit-scrollbar-thumb {
  background: #f4ecec
}​ */
::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(89, 147, 241, 0.5);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(89, 147, 241, 0.5);
}
